/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */



function loadAcfMap(){
    
    jQuery('.acf-map').each(function () {
        map = new_map(jQuery(this,jQuery));
    });
}
function leftrightsame(){ 
    //console.log('leftrightsame');
        if(!jQuery('.right-content').length){
            return;
        }
        if(jQuery(window).width()  > 992){
         jQuery('.right-content').height(jQuery('.left-content').height());
      
        var topLimit =  jQuery('.right-content').offset().top - 40 ;//margin

        if(jQuery('.linka-vypis').length){
            var bottomLimit =  jQuery('.linka-vypis').offset().top - 60;   
        }else{
            var bottomLimit =  jQuery('.edunett-text-block').offset().top - 60;   
        }
        var stickerHeight = jQuery('#sticker').outerHeight() ;   
      jQuery(window).scroll(function(){
        if((window.pageYOffset > topLimit) && (window.pageYOffset < (bottomLimit - stickerHeight))){
            jQuery('#sticker').css('margin-top',window.pageYOffset - topLimit + 20 );
        }
      });
    }else{
        jQuery('.right-content').height(jQuery('#sticker').height() + 60);      
        jQuery('#sticker').css('margin-top','20px');
    }
  }

(function ($) {



    var topLinksMenu = {
        hamb: '',
        init: function () {
            $('.top-links .navbar-toggle').click(function () {

                if ($(this).parent().parent().find('div:last').is(":visible")) {
                    $(this).parent().removeClass('closeButton');
                    $(this).parent().parent().find('div:last').hide();
                    $(this).html(topLinksMenu.hamb);
                } else {
                    $(this).parent().addClass('closeButton');
                    $(this).parent().parent().find('div:last').show();

                    topLinksMenu.hamb = $(this).html();
                    //$(this).html("<i class='glyphicon glyphicon-remove'></i>")
                    if($('.edunet-header').length){
                         $(this).html("<img style='width:22px;' src='/wp-content/themes/escare/dist/images/icoClose2.png'  alt='close menu' />");  
                    }
                    else{
                        $(this).html("<img style='width:22px;' src='/wp-content/themes/escare/dist/images/menu_close.png'  alt='close menu' />");    
                    }
                    
                }
            });
        }

    }

    var mobileLinksMenu = {
        hamb: '',
        init: function () {
            $('.mobile-links .navbar-toggle').click(function () {
                var width = $(document).width();

                if ($(this).parent().parent().find('div:last').is(":visible")) {
                    $(this).parent().removeClass('closeButton');
                    $(this).parent().parent().find('div:last').hide();
                    $(this).html(mobileLinksMenu.hamb);
                } else {
                    $(this).parent().addClass('closeButton');
                    //var menu = $(this).parent().parent().find('.mobile-links-wrapper');    
                    $(this).parent().parent().find('div:last').show();
                    $(this).parent().parent().find('div:last').width(width).show();
                    //$(this).parent().append(menu);                
                    mobileLinksMenu.hamb = $(this).html();
                   // $(this).html("<img src='/wp-content/themes/escare/dist/images/menu_close.png'  alt='close menu' />");
                    if($('.edunet-header').length){
                         $(this).html("<img src='/wp-content/themes/escare/dist/images/icoClose2.png'  alt='close menu' />");  
                    }
                    else{
                        $(this).html("<img src='/wp-content/themes/escare/dist/images/menu_close.png'  alt='close menu' />");    
                    }                    
                    
                }
            });
        }

    }



    var map = null;
    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var Sage = {
        // All pages
        'common': {
            init: function () {
                // JavaScript to be fired on all pages
                topLinksMenu.init();
                mobileLinksMenu.init();
                if ($('[data-equal-height]').length) {
                   // $('[data-equal-height]').equalizeHeight(200);
                    
                }
                if ($('.data-height-item').length) {
                   $('.data-height-item').matchHeight();                    
                }
                var pocetosob = 0;
                if($('.objednat_button').length){
                    $('.osoby-pridat').click(function(){
                        pocetosob++;
                         $('input[name="pocet_osob"]').val(pocetosob);  
                    });
                    $('.osoby-ubrat').click(function(){
                        if(pocetosob > 0){
                            pocetosob--;
                            $('input[name="pocet_osob"]').val(pocetosob);
                        }                         
                        if(pocetosob == 0){
                            $('input[name="pocet_osob"]').val('');
                        }
                    });
                   
                }               
                if($('.single-skoleni').length &&($('.hodnoceni-typ-vypis').length)){
                    $('.hodnoceni').css('cursor','pointer');
                    $('.hodnoceni').click(function(){                        
                        $('html,body').animate({scrollTop:$('.hodnoceni-typ-vypis').offset().top},1000);
                    });
                }
                if($('.single-edunett').length &&($('.hodnoceni-typ-vypis').length)){
                    $('.hodnoceni').css('cursor','pointer');
                    $('.hodnoceni').click(function(){
                        $('html,body').animate({scrollTop:$('.hodnoceni-typ-vypis').offset().top},1000);
                    });
                }
                
                $( window ).resize(function(){ leftrightsame();});
                setTimeout(leftrightsame,2000);
                
                
            },            
            finalize: function () {
                // JavaScript to be fired on all pages, after page specific JS is fired
            }
        },
        // Home page
        'home': {
            init: function () {
                // JavaScript to be fired on the home page
                $('.show-course').click(function (e) {
                    var ref = $(this).find('a').attr('href');
                    window.location.href = ref;
                });
            },
            finalize: function () {
                // JavaScript to be fired on the home page, after the init JS
            }
        },
        // About us page, note the change from about-us to about_us.
        'kontakt_edunett': {
            init: function () {

                var script = document.createElement('script');
                script.type = 'text/javascript';
                script.src = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyBTiftjsj049meMMsDuGGonBsdKKyK7roc&v=3.exp&callback=loadAcfMap';
                document.body.appendChild(script);

                
            }
        },
        'edunet': {
            init : function(){
             
               
            }
        },
        'prumyslove_inzenyrstvi': {
            init : function(){
               
               
            }
        },
        // About us page, note the change from about-us to about_us.
        'kontakt': {
            init: function () {

                var script = document.createElement('script');
                script.type = 'text/javascript';
                script.src = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyBTiftjsj049meMMsDuGGonBsdKKyK7roc&v=3.exp&callback=loadAcfMap';
                document.body.appendChild(script);


            }
        },
        'about_us': {
            init: function () {
                // JavaScript to be fired on the about us page
            }
        }
    };

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function (func, funcname, args) {
            var fire;
            var namespace = Sage;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function () {
            // Fire common init JS
            UTIL.fire('common');

            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });

            // Fire common finalize JS
            UTIL.fire('common', 'finalize');
        }

    };

    // Load Events
    $(document).ready(UTIL.loadEvents);





})(jQuery); // Fully reference jQuery after this point.

jQuery(document).ready(function(){
    if(jQuery('.chocolat-parent').length){
        jQuery('.chocolat-parent').Chocolat();
    }
});

